import * as React from "react"


const IndexPage = () => {
  return (
    <main>
      <h1>Hello, STUDIO WT.</h1>
    </main>
  )
}

export default IndexPage
